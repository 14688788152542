<template>
  <!-- eslint-disable -->
  <b-sidebar
    id="add-new-speciality"
    :visible="isAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @change="(val) => $emit('update:is-add', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ isEdit ? 'Editar' : 'Agregar nueva' }} Dueño</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click=";[hide(), resetForm()]"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ invalid }" ref="refFormObserver">
        <!-- Form -->

        <b-form class="p-2" @submit.prevent="onSubmit(items)">
          <validation-provider #default="{ errors }" name="project" rules="requeridoE">
            <b-form-group label="Proyecto" label-for="project">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="proyectos"
                label="code"
                input-id="project"
                :reduce="(proyectos) => proyectos.id"
                placeholder="Proyecto"
                v-model="project_id"
                @input="selectArea(project_id)"
                class="select-obra"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          
          <validation-provider #default="{ errors }" name="tipo" rules="requeridoE">
            <validation-provider
              #default="{ errors }"
              class="d-flex flex-column h-100 justify-content-start p-05"
              name="tipo"
              rules="requeridoE"
            >
              <b-form-group label="Tipo" label-for="tipo">
                <b-form-radio v-model="items.tipo" name="type" value="001" class="mb-1">
                  Proyecto
                </b-form-radio>
                <b-form-radio v-model="items.tipo" name="type" value="002" class="mb-1">
                  Subcontratista / Servicios
                </b-form-radio>
                <b-form-radio v-model="items.tipo" name="type" value="003" class="mb-1">
                  Cliente
                </b-form-radio>
                <b-form-radio v-model="items.tipo" name="type" value="004" class="">
                  Oficina Principal
                </b-form-radio>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <!-- <b-form-group label="Tipo" label-for="tipo">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tipos"
                label="name"
                input-id="tipo"
                :reduce="(tipos) => tipos.code"
                placeholder="Tipo"
                v-model="items.tipo"
              >
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group> -->
          </validation-provider>
          <validation-provider #default="{ errors }" name="area" rules="requeridoE">
            <b-form-group label="Area" label-for="area">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="records"
                label="code"
                input-id="area"
                :reduce="(records) => records.id"
                placeholder="Area"
                v-model="items.area_id"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="name" rules="requeridoE">
            <b-form-group label="Nombre" label-for="name">
              <b-form-input
                id="name"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Nombre"
                v-model="items.name"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="email" rules="requeridoE|correo">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Email"
                v-model="items.email"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="telefono" rules="">
            <b-form-group label="Telefono" label-for="telefono">
              <b-form-input
                id="telefono"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Telefono"
                v-model="items.telefono"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isDisabled" small />
              <span v-if="isDisabled" class="px-1">guardando...</span>
              <span v-else>{{ isEdit ? 'Actualizar' : 'Agregar' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click=";[hide(), resetForm()]"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ProjectsService from '@/services/ProjectsService'
import AreasService from '@/services/AreasService'
import OwnerService from '@/services/OwnerService'
Vue.use(BootstrapVue)
export default {
  directives: {
    Ripple
  },
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required,
      // speciality: {
      //   title: ''
      // },
      isEdit: false,
      isDisabled: false,
      records: [],
      proyectos: [],
      project_id: null,
      tipos: [
        {
          code: '001',
          name: 'Proyecto'
        },
        {
          code: '002',
          name: 'Subcontratista / Servicios'
        },
        {
          code: '003',
          name: 'Cliente'
        },
        {
          code: '004',
          name: 'Oficina Principal'
        }
      ],
      items: {
        area_id: null,
        name: '',
        email: '',
        tipo: '001',
        telefono: ''
      },
      temp: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const url = `?limit=100&`
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
      console.log('respProyectos', respProyectos)
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
      }
    },
    async selectArea(id) {
      let url =
        `?limit=100&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const resp = await AreasService.getAreas(url, this.$store)
      console.log('resp', resp)
      if (resp.status) {
        this.records = resp.data.rows
      }
    },
    setData(items, id) {
      if (Object.entries(items).length !== 0) {
        this.project_id = id
        this.selectArea(id)
        this.temp = items
        this.items = items
        this.isEdit = true
      } else {
        this.temp = {}
        this.items = {
          tipo: '001',
          area_id: null,
          name: '',
          email: '',
          telefono: ''
        }
        this.isEdit = false
      }
      console.log('temp EN ADD', this.temp)
    },
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.isEdit = false
      this.project_id = null
      this.items = {
        area_id: null,
        tipo: '001',
        name: '',
        email: ''
      }
    },
    async onSubmit(data) {
      console.log('data', data)
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          this.isDisabled = true
          let resp = ''
          let datos = {}
          datos.area_id = data.area_id
          datos.name = data.name
          datos.email = data.email
          datos.type = data.tipo
          datos.phone = data.telefono
          if (this.isEdit == false) {
            resp = await OwnerService.saveOwner(datos, this.$store)
          } else {
            let id = data.id
            resp = await OwnerService.updateOowner(id, datos, this.$store)
          }
          console.log('datos', datos)
          if (resp.status) {
            this.$swal({
              title: this.isEdit == true ? 'Actualizado' : 'Registrado',
              text: 'El dueño ha sido ' + (this.isEdit == true ? 'actualizado.' : 'registrado.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            console.log('this.$parent.$parent', this.$parent.$parent)
            this.$parent.$parent.$parent.getData()
            this.$emit('update:is-add', false)
            this.resetForm()
          } else {
            this.$swal({
              title: 'Error!',
              text:
                ' Hubo un error al ' +
                (this.isEdit == true ? 'actualizar' : 'registrar') +
                ' el dueño',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
          this.isDisabled = false
        } else {
          console.log('no validado')
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.p-05{
  padding: .5rem;
}
</style>
