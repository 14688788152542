<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <filters ref="filters" />
      <b-card no-body>
        <b-table
          class="position-relative"
          :hover="true"
          :items="records"
          responsive
          :fields="fields"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
          no-border-collapse
        >
          <!-- Column: Actions -->
          <template #cell(area)="data">
            <span>{{data.item.area.code}} - {{data.item.area.description}}</span>
          </template>       
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group label-cols="4" label-cols-md="4" label-size="md" label="Entradas" label-for="input-md">
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
     
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import filters from './filters.vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OwnerService from '@/services/OwnerService'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      showLoading: false,
      fields: [
        { key: 'area', label: 'Area - Descripción', sortable: false },
        { key: 'name', label: 'Nombre', sortable: false },
        { key: 'email', label: 'Email', sortable: false },       
      ],
      form: {
        area_id: null,
        name: '',
        email: '',
        telefono: '',
        tipo: ''
      },
      records: [],
      documentSelect: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc'
    }
  },
  components: {
    vSelect,
    filters
  },
  mounted() {
    //this.getData()
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    async getData() {
      // this.totalElements = 0
      this.showLoading = true
      const url = `?limit=${this.showEntrie}&page=${this.currentPage}&filter=`+JSON.stringify(this.arrayFilters)
      const resOwner = await OwnerService.getOwners(url, this.$store)
      console.log('resOwner', resOwner)
      if (resOwner.status) {
        this.records = resOwner.data.rows
        this.totalElements=resOwner.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    edit(item) {
      console.log('item',item)
      this.form.id = item.id
      this.form.name = item.name
      this.form.email = item.email
      this.form.area_id = item.area.id
      this.form.telefono = item.phone
      this.form.tipo = item.type
      console.log('this.form', this.form)
      this.$refs.filters.isAdd = true
      this.$refs.filters.$children[0].setData(this.form,item.area.project.id)
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar este dueño?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {          
          this.deleteData(data.id)
        }
      })
    },
    async deleteData(id) {
      console.log('id', id)
      const resp = await OwnerService.deleteOwner(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
          this.$swal({
            icon: 'success',
            title: 'Eliminado!',
            text: 'El dueño ha sido eliminado.',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        this.getData()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar el dueño seleccionado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
