var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-speciality","visible":_vm.isAdd,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-add', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.isEdit ? 'Editar' : 'Agregar nueva')+" Dueño")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(_vm.items)}}},[_c('validation-provider',{attrs:{"name":"project","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Proyecto","label-for":"project"}},[_c('v-select',{staticClass:"select-obra",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.proyectos,"label":"code","input-id":"project","reduce":function (proyectos) { return proyectos.id; },"placeholder":"Proyecto"},on:{"input":function($event){return _vm.selectArea(_vm.project_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"tipo","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('validation-provider',{staticClass:"d-flex flex-column h-100 justify-content-start p-05",attrs:{"name":"tipo","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo","label-for":"tipo"}},[_c('b-form-radio',{staticClass:"mb-1",attrs:{"name":"type","value":"001"},model:{value:(_vm.items.tipo),callback:function ($$v) {_vm.$set(_vm.items, "tipo", $$v)},expression:"items.tipo"}},[_vm._v(" Proyecto ")]),_c('b-form-radio',{staticClass:"mb-1",attrs:{"name":"type","value":"002"},model:{value:(_vm.items.tipo),callback:function ($$v) {_vm.$set(_vm.items, "tipo", $$v)},expression:"items.tipo"}},[_vm._v(" Subcontratista / Servicios ")]),_c('b-form-radio',{staticClass:"mb-1",attrs:{"name":"type","value":"003"},model:{value:(_vm.items.tipo),callback:function ($$v) {_vm.$set(_vm.items, "tipo", $$v)},expression:"items.tipo"}},[_vm._v(" Cliente ")]),_c('b-form-radio',{attrs:{"name":"type","value":"004"},model:{value:(_vm.items.tipo),callback:function ($$v) {_vm.$set(_vm.items, "tipo", $$v)},expression:"items.tipo"}},[_vm._v(" Oficina Principal ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"area","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Area","label-for":"area"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.records,"label":"code","input-id":"area","reduce":function (records) { return records.id; },"placeholder":"Area"},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.area_id),callback:function ($$v) {_vm.$set(_vm.items, "area_id", $$v)},expression:"items.area_id"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"name","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"name","trim":"","placeholder":"Nombre"},model:{value:(_vm.items.name),callback:function ($$v) {_vm.$set(_vm.items, "name", $$v)},expression:"items.name"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"requeridoE|correo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"email","trim":"","placeholder":"Email"},model:{value:(_vm.items.email),callback:function ($$v) {_vm.$set(_vm.items, "email", $$v)},expression:"items.email"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"telefono","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono","label-for":"telefono"}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"telefono","trim":"","placeholder":"Telefono"},model:{value:(_vm.items.telefono),callback:function ($$v) {_vm.$set(_vm.items, "telefono", $$v)},expression:"items.telefono"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2 justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"disabled":invalid,"variant":"primary","type":"submit"}},[(_vm.isDisabled)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isDisabled)?_c('span',{staticClass:"px-1"},[_vm._v("guardando...")]):_c('span',[_vm._v(_vm._s(_vm.isEdit ? 'Actualizar' : 'Agregar'))])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){;[hide(), _vm.resetForm()]}}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }